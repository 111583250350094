import axios from "axios";
import React, { SelectHTMLAttributes, useState } from "react";
import { iOpt, iSiteResponse, } from "../../Types/Types";
import { AutoComplete, Select } from "antd";
import api from "../../helpers/axios";

type iProps = SelectHTMLAttributes<HTMLSelectElement> & {
    placeHolder?: string;
    disabled?: boolean;
    label?: string;
    required?: boolean;
    value: string | number | undefined;
    setValue: Function;
}

export default function SelectSites({ value, label, disabled, setValue, ...rest }: iProps) {
    const [options, setOptions] = useState<iOpt[]>([]);
    React.useEffect(() => {
        api.get('/sites').then((response) => {
            const tempOpt = [] as iOpt[];
            (response.data as iSiteResponse[]).map(item => {
                tempOpt.push({
                    value: item._id.toString(),
                    label: item.nome
                })
            })
            setOptions(tempOpt);
        });

    }, []);
    const handleChange = (value: string | string[]) => {
        console.log(`Selected: ${value}`);
        setValue(value)
    };
    return (
        <div>
            {label ? (
                <label
                    className="block mb-2 text-sm text-navy-700 font-bold"
                >
                    {label}
                </label>
            ) : (
                ''
            )}
            <Select
                size="large"
                placeholder="Contratante"
                // defaultValue={[]}
                onChange={handleChange}
                value={options.find(item => item.value === value)?.label || ""}
                style={{ width: "100%" }}
                options={options}
            />
        </div>
    );
}
