import { useContext } from "react";
import { userContext } from "../../context/usuarioContext";

export function isAdm(){
    const { user } = useContext(userContext);
    return user?._id ? user.idPermissao.filter(item => item._id === ADMID).length > 0 : false;
}
export function getSiteID(){
    const { user } = useContext(userContext);
    console.log()
    return user?.idSite ? user?.idSite._id : 0;
}
export const ADMID = "649eeb1aba394ede4c21d8bb"
// export const isADM = user?._id? user.idPermissao.filter(item => item._id === ADMID).length > 0 : false