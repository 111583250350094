
import './Main.css';
import Home from './Views/Home/Home';
import Login from './Views/login';
import Usuario from './Views/Usuario/Usuario';
import LandingPage from './Views/LandingPage';
import { Route, Switch } from 'react-router-dom';
import PrivateRoute from './Rotas/privateRoutes';
import NotFound from './Views/NotFound';
import 'react-toastify/dist/ReactToastify.css';
import Posts from './Views/Posts';
import Imagens from './Views/Imagens';
import Mensageria from './Views/Mensageria';
import Configuracao from './Views/configuracao';
import Sites from './Views/Sites';
import Contratantes from './Views/contratantes';
import 'antd/dist/reset.css';
import { UserProvider, userContext } from './context/usuarioContext';
import Clientes from './Views/Clientes/Clientes';
import Permissoes from './Views/Permissoes';
import Modulos from './Views/Modulos';
import { useContext } from 'react';
interface props {
  user: String;
  setUser: Function;
}
function App() {
  return (
    <UserProvider>
      <Switch>
        <Route path="/" exact component={LandingPage} />

        <Route path="/login" component={Login} />
        <PrivateRoute path="/usuario" component={Usuario} />
        <PrivateRoute path="/dashboard" component={Home} />
        <PrivateRoute path="/posts" component={Posts} />
        <PrivateRoute path="/imagens" component={Imagens} />
        <PrivateRoute path="/mensageria" component={Mensageria} />
        <PrivateRoute path="/configuracao" component={Configuracao} />
        <PrivateRoute path="/clientes" component={Clientes} />
        
        <PrivateRoute path="/sites" component={Sites} adm/>
        <PrivateRoute path="/contratantes" component={Contratantes} adm/>
        <PrivateRoute path="/permissoes" component={Permissoes} adm/>
        <PrivateRoute path="/modulos" component={Modulos} adm/>

        {/* a rota abaixo deve sempre ser a ultima */}
        <Route path="*" component={NotFound} />
      </Switch>
    </UserProvider>
  );
}

export default App;