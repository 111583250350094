import React, { useContext, useState } from "react";
import axios from "axios";
import { Button, Col, Form, Input, List, QRCode, Radio, RadioChangeEvent, Row } from "antd";
import TextArea from "antd/es/input/TextArea";
import { toast } from "react-toastify";
import { userContext } from "../../context/usuarioContext";
import SelectGruposCliente from "../../Components/Select/selectGruposClientes";
import { iClienteData } from "../../Types/Types";
import api from "../../helpers/axios";

export default function WhatsApp() {
    const { user } = useContext(userContext);
    const [qr, setQr] = useState("");
    const [qrLoading, setQrLoading] = useState(false);
    React.useEffect(() => {
        // const fetchData = async () => {
        //     try {
        //         setQrLoading(true)
        //         api.post('/WhatsAppCreate', {
        //             id: user?.idSite._id
        //         }).then((response) => {
        //             console.log("QR CODE", response.data.qrcode);
        //             setQr(response.data.qrcode);
        //             setQrLoading(false);
        //         });
        //     } catch (error) {
        //         console.error(error);
        //     }
        // };

        // Chama a função fetchData imediatamente
        // fetchData();

        // Define um intervalo para chamar a função fetchData a cada 20 segundos
        // const interval = setInterval(fetchData, 30000);
        // Limpa o intervalo quando o componente é desmontado
        // return () => clearInterval(interval);
    }, []);
    const [para, setPara] = useState<string[]>([]);
    const [mensagem, setMensagem] = useState<string>("");
    const [grupos, setGrupos] = useState<string[]>([]);
    const [ClientesLista, setClientesLista] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    function EnviarMensagem() {
        if (valueRadio === "individual") {
            api.post('/WhatsAppSend', {
                id: user?.idSite._id,
                number: para,
                message: mensagem,
            });
        } else {
            ClientesLista.map(item => {
                api.post('/WhatsAppSend', {
                    id: user?.idSite._id,
                    number: item,
                    message: mensagem,
                });
            })
        }

    }
    async function OnSubmit() {
        try {
            setLoading(true)
            const resposta = await EnviarMensagem();
            toast.success("Mensagens Enviadas com Sucesso");
        } catch (error) {
            toast.error(`${error}`);
        }
        setLoading(false)
    }

    const [valueRadio, setValueRadio] = useState('individual');
    const onChangeRadio = ({ target: { value } }: RadioChangeEvent) => {
        setValueRadio(value);
    };
    const optionsRadio = [
        { label: 'Envio Individual', value: 'individual' },
        { label: 'Envio em Grupo', value: 'emailG' },
    ];
    function setEmailList(ids: string[]) {
        api.post('/clienteGrupo', { idGrupo: ids }).then(response => {
            const res = response.data as iClienteData[]
            const tempCliList: string[] = [];
            res.map(item => {
                tempCliList.push(`55${item.telefone}`);
            })
            setClientesLista(tempCliList);
        });
    }
    return (
        <Form
            layout="vertical"
            onFinish={(e) => { OnSubmit(); }}
            id="FromCad"
        >
            <Row gutter={10}>
                <Col span={24}>
                    <Form.Item label="" >
                        <Radio.Group
                            options={optionsRadio}
                            onChange={onChangeRadio}
                            value={valueRadio}
                            optionType="button"
                            buttonStyle="solid"
                        />
                    </Form.Item>
                </Col>
                <Col span={24} md={12}>
                    <Row gutter={10}>
                        <Col span={24}>
                            <Form.Item label="QrCode de conexão WhatsApp" className="text-center" >
                                <QRCode value={qr || "-"} status={qrLoading ? "loading" : "active"} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            {valueRadio === "individual" ?
                                <Form.Item label="Email" >
                                    <Input required size="large" id="numero" placeholder="Numero Wpp" type="tel" value={para} onChange={(e) => setPara([e.target.value])} />
                                </Form.Item>
                                :
                                <Form.Item label="Selecione o Grupo" >
                                    <SelectGruposCliente value={grupos} setValue={setGrupos} execOnChange={setEmailList} />
                                </Form.Item>
                            }

                        </Col>
                        {valueRadio !== "individual" ?
                            <Col span={24}>
                                {/* <TextArea required rows={10} value={ClientesLista.toString()} disabled /> */}
                                <List
                                    size="large"
                                    header={<div>Clientes que vão receber a mensagem:</div>}
                                    bordered
                                    dataSource={ClientesLista}
                                    renderItem={(item) => <List.Item>{item}</List.Item>}
                                />
                            </Col>
                            :
                            ""
                        }
                    </Row>
                </Col>
                <Col span={24} md={12}>
                    <Row gutter={10}>
                        <Col span={24}>
                            <Form.Item label="Mensagem" >
                                <TextArea required rows={10} placeholder="Mensagem" value={mensagem} onChange={(e) => setMensagem(e.target.value)} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" size="large" loading={loading} disabled={qrLoading}>
                                    Enviar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Form>
    )
}