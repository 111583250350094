import { Divider, Space } from 'antd';
import React, { useState } from 'react';
import { iImagensListaCategoria, iPosts } from '../../Types/Types';
import { Typography } from 'antd';
import api from '../../helpers/axios';
import ImageCard from './components/ImageCard';
const { Title } = Typography;
type props = {
    data: iImagensListaCategoria[];
}
export default function ImageList({ data }: props) {
    console.log(data);
    return (
        <>
            {
                data.map(cate => (
                    <>
                        <h3 className='my-10'>{cate.Categoria}</h3>
                        <Divider />
                        <div className="grid grid-cols-4 gap-1">
                            {
                                cate.imagens.map(item => (
                                    <div className="col-span-4 md:col-span-2 lg:col-span-1">
                                        <ImageCard Titulo={item.titulo} descricao={item.descricao} categoria={item.categoria} imagem={item.imagem} />
                                    </div>

                                ))
                            }
                        </div>
                        <Divider />
                    </>
                ))
            }
            
        </>
    )
}