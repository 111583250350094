import React, { useContext, useState } from "react";
import axios from "axios";
import { Button, Col, Form, Input, List, Radio, RadioChangeEvent, Row } from "antd";
import TextArea from "antd/es/input/TextArea";
import { toast } from "react-toastify";
import { userContext } from "../../context/usuarioContext";
import SelectGruposCliente from "../../Components/Select/selectGruposClientes";
import { iClienteData } from "../../Types/Types";
import api from "../../helpers/axios";

export default function Email() {
    const { user } = useContext(userContext);
    React.useEffect(() => {
        api.get('/usuarios').then((response) => {
            console.log(response.data);
        });
    }, []);
    const [para, setPara] = useState<string[]>([]);
    const [assunto, setAssunto] = useState<string>("");
    const [mensagem, setMensagem] = useState<string>("");
    const [grupos, setGrupos] = useState<string[]>([]);
    const [ClientesLista, setClientesLista] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    function EnviarEmail() {
        return api.post('/enviaEmail', {
            para: valueRadio === "emailI" ? para : ClientesLista,
            de: user?.nome,
            assunto: assunto,
            mensagem: mensagem,
        });
    }
    async function OnSubmit() {
        try {
            setLoading(true)
            const resposta = await EnviarEmail();
            toast.success("Mensagens Enviadas com Sucesso");
        } catch (error) {
            toast.error(`${error}`);
        }
        setLoading(false)
    }

    const [valueRadio, setValueRadio] = useState('emailI');
    const onChangeRadio = ({ target: { value } }: RadioChangeEvent) => {
        setValueRadio(value);
    };
    const optionsRadio = [
        { label: 'Envio Individual', value: 'emailI' },
        { label: 'Envio em Grupo', value: 'emailG' },
    ];
    function setEmailList(ids: string[]) {
        api.post('/clienteGrupo', { idGrupo: ids }).then(response => {
            const res = response.data as iClienteData[]
            const tempCliList: string[] = [];
            res.map(item => {
                tempCliList.push(item.email);
            })
            setClientesLista(tempCliList);
        });
    }
    return (
        <Form
            layout="vertical"
            onFinish={(e) => { OnSubmit(); }}
            id="FromCad"
        >
            <Row gutter={10}>
                <Col span={24}>
                    <Form.Item label="" >
                        <Radio.Group
                            options={optionsRadio}
                            onChange={onChangeRadio}
                            value={valueRadio}
                            optionType="button"
                            buttonStyle="solid"
                        />
                    </Form.Item>
                </Col>
                <Col span={24} md={12}>
                    <Row gutter={10}>
                        <Col span={24}>
                            {valueRadio === "emailI" ?
                                <Form.Item label="Email" >
                                    <Input required size="large" id="Nome" placeholder="Nome" type="email" value={para} onChange={(e) => setPara([e.target.value])} />
                                </Form.Item>
                                :
                                <Form.Item label="Selecione o Grupo" >
                                    <SelectGruposCliente value={grupos} setValue={setGrupos} execOnChange={setEmailList} />
                                </Form.Item>
                            }

                        </Col>
                        <Col span={24}>
                            <Form.Item label="Assunto" >
                                <Input required size="large" id="Assunto" placeholder="Assunto" type="text" value={assunto} onChange={(e) => setAssunto(e.target.value)} />
                            </Form.Item>
                        </Col>
                        {valueRadio !== "emailI" ?
                            <Col span={24}>
                                {/* <TextArea required rows={10} value={ClientesLista.toString()} disabled /> */}
                                <List
                                    size="large"
                                    header={<div>Clientes que vão receber a mensagem:</div>}
                                    bordered
                                    dataSource={ClientesLista}
                                    renderItem={(item) => <List.Item>{item}</List.Item>}
                                />
                            </Col>
                            :
                            ""
                        }
                    </Row>
                </Col>
                <Col span={24} md={12}>
                    <Row gutter={10}>
                        <Col span={24}>
                            <Form.Item label="Mensagem" >
                                <TextArea required rows={10} placeholder="Mensagem" value={mensagem} onChange={(e) => setMensagem(e.target.value)} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" size="large" loading={loading}>
                                    Enviar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Form>
    )
}