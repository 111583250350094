import { LikeOutlined, MessageOutlined, StarOutlined } from '@ant-design/icons';
import { Avatar, Card, List, Space } from 'antd';
import Meta from 'antd/es/card/Meta';
import React, { useEffect, useState } from 'react';
import { AiFillEdit } from 'react-icons/ai';
import { iOpt, iPosts } from '../../Types/Types';
import axios from 'axios';
import api from '../../helpers/axios';
interface props {
    editFuncion:Function
}

export default function PostList({editFuncion}:props) {
    const [postList,setPostList] = useState<iPosts[]>([]);
    React.useEffect(() => {
        api.get('/posts').then((response) => {
            setPostList(response.data);
        });
    }, []);

    const IconText = ({ icon, text }: { icon: React.FC; text: string }) => (
        <Space>
            {React.createElement(icon)}
            {text}
        </Space>
    );
    async function editar(id: string){
        await editFuncion(id);
        api.get('/posts').then((response) => {
            setPostList(response.data);
        });
    }
    return (
        <List
            grid={{ gutter: 16, column: 4 }}
            dataSource={postList}
            renderItem={(item) => (
                <List.Item>
                    <Card
                        // cover={
                        //     <img
                        //         alt="example"
                        //         src="https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"
                        //     />
                        // }
                    actions={[
                        <AiFillEdit key="edit"  size={15} onClick={()=>editar(item._id)}/>,
                        // <SettingOutlined key="setting" />,
                        // <EllipsisOutlined key="ellipsis" />,
                    ]}
                    >
                        <span className='w-full block text-right'><i className='text-right'>{new Date(item.dataCriacao).toLocaleDateString()}</i></span> 
                        <Meta
                            // avatar={<Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel" />}
                            title={item.titulo}
                            description={item.descricao.substring(0,60) + "..."}
                        />
                        
                    </Card>
                </List.Item>
            )}
        />
    )
}