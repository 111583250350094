import React, { useState } from "react";
import MainCard from "../../Components/MainCard";
import axios from "axios";
import { iOpt, iUserData } from "../../Types/Types";
import { toast } from "react-toastify";
import { Label } from "@headlessui/react/dist/components/label/label";
import Tabs, { TabsProps } from "antd/es/tabs";
import Email from "./email";
import api from "../../helpers/axios";
import { Card } from "antd";
import WhatsApp from "./whatsapp";

interface idSite {
    _id: string;
    nome: string;
    ativado: boolean;
    empresa: string;
}

export default function Mensageria() {
    const [users, setUsers] = React.useState<iUserData[]>([]);
    React.useEffect(() => {
        api.get('/usuarios').then((response) => {
            console.log(response.data)
            setUsers(response.data);
        });
    }, []);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectValue, setSelectValue] = useState<string>("");
    const [nome, setNome] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [senha, setSenha] = useState<string>("");

    function cadastro() {
        return api.post('/usuarios', {
            nome: nome,
            email: email,
            senha: senha,
            idSite: selectValue,
        });
    }
    async function OnSubmit() {
        try {
            const resposta = await cadastro();
            toast.success("Cadastrado com Sucesso");
            users.push(resposta.data)
        } catch (error) {
            console.log("aaa")
            toast.error(`${error}`);
        }

    }
    const [key,setKey] = useState("1");
    const onChange = (key: string) => {
        console.log(key);
    };

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: `Envio de Email`,
            children: <Email />,
        },
        // {
        //     key: '2',
        //     label: `Tab 2`,
        //     children: `Content of Tab Pane 2`,
        // },
        {
            key: '2',
            label: `Envio de Whatsapp`,
            children: <WhatsApp />,
        },
    ];
    return (
        <MainCard title="Mensageria">
            <Card size='small'>
                <Tabs defaultActiveKey="1" items={items} onChange={onChange} size="large" />;
            </Card>
        </MainCard>
    )
}