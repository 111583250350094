import { Tabs, TabsProps } from "antd";
import TabCliente from "./components/Clientes"
import Grupo from "./components/Grupo";
import MainCard from "../../Components/MainCard";

export default function Clientes() {
    const items: TabsProps['items'] = [
        {
            key: '1',
            label: `Clientes`,
            children: <TabCliente />,
        },
        {
            key: '2',
            label: `Grupo de Clientes`,
            children: <Grupo />,
        },
    ];
    return (
        <MainCard title={""}>
            <Tabs defaultActiveKey="1" items={items} size="large" />
        </MainCard>
    )
}