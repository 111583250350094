import React, { useState } from "react";
import MainCard from "../../Components/MainCard";
import axios from "axios";
import { iConteudo, iPosts, iUserData } from "../../Types/Types";
import { toast } from "react-toastify";
import PostList from "../../Components/PostList";
import { Button, Col, Collapse, Drawer, Form, Input, Row, Space } from "antd";
import SelectSites from "../../Components/Select/selectSites";
import { AiOutlineMinusCircle } from "react-icons/ai";
import { text } from "stream/consumers";
import TextArea from "antd/es/input/TextArea";
import api from "../../helpers/axios";
const { Panel } = Collapse;
interface idSite {
    _id: string;
    nome: string;
    ativado: boolean;
    empresa: string;
}

export default function Posts() {
    const [editId, setEditId] = useState<string>();
    const [isEdit, setIsEdit] = useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const toggleDrawer = () => {
        setIsEdit(false);
        clearInputs();
        setIsDrawerOpen(!isDrawerOpen);
    };
    const [selectValue, setSelectValue] = useState<string>("");
    const [categoria, setCategoria] = useState<string>("");
    const [titulo, setTitulo] = useState<string>("");
    const [descricao, setDescricao] = useState<string>("");
    const [subtitulo, setSubtitulo] = useState<string>("");
    const [conteudo, setConteudo] = useState<string>("");
    const [ativo, setAtivo] = useState<boolean>(true);
    const [campo1, setCampo1] = useState<string>("");
    const [campo2, setCampo2] = useState<string>("");
    const [campo3, setCampo3] = useState<string>("");

    function cadastro() {
        return api.post('/posts', {
            categoria,
            titulo,
            descricao,
            conteudo,
            ativo,
            campo1,
            campo2,
            campo3,
            idSite: selectValue,
        });
    }
    function editar() {
        return api.put(`/posts/64892ca863e1e446435bc283`, {
            categoria,
            titulo,
            descricao,
            conteudo,
            ativo,
            campo1,
            campo2,
            campo3,
            idSite: selectValue,
        });
    }
    async function OnSubmit() {
        try {
            if(isEdit){
                const resposta = await editar();
            } else {
                const resposta = await cadastro();
            }
            
            toast.success( isEdit ? "Editado com Sucesso" :"Cadastrado com Sucesso");
            toggleDrawer();
        } catch (error) {
            console.log("aaa")
            toast.error(`${error}`);
        }

    }
    function clearInputs() {
        setSelectValue("");
        setCategoria("");
        setTitulo("");
        setDescricao("");
        setSubtitulo("");
        setConteudo("");
        setCampo1("");
        setCampo2("");
        setCampo3("");
    }
    
    function edit(id:string){
        setIsEdit(true);
        api.get(`/posts/${id}`).then((response) => {
            const res = response.data as iPosts;
            setEditId(res._id.toString());
            console.log(response.data)
            setCategoria(res.categoria);
            setTitulo(res.titulo);
            setDescricao(res.descricao);
            // setSubtitulo(res.subtitulo);
            setConteudo(res.conteudo);
            setAtivo(res.ativo);
            setCampo1(res.campo1);
            setCampo2(res.campo2);
            setCampo3(res.campo3);
            setSelectValue(res.idSite._id.toString());
            setIsDrawerOpen(true);
        });
    }
    return (
        <MainCard title="Posts - Pulicações">
            <div className="grid grid-cols-4 gap-4">
                <div className="col-span-4 text-right">
                    <Button type="primary" size="large" key="back" onClick={toggleDrawer}>
                        Novo Post
                    </Button>
                </div>
                <div className="col-span-4">
                    <PostList editFuncion={edit} />
                </div>
            </div>
            <Drawer
                title="Cadastro de Contratante"
                placement="right"
                onClose={toggleDrawer}
                open={isDrawerOpen}
                width={720}
                extra={
                    <Space>
                        <Button onClick={toggleDrawer}>Cancel</Button>
                        <Button htmlType="submit" form="FormCad" type="primary">
                            Salvar
                        </Button>
                    </Space>
                }
            >
                <Form
                    layout="vertical"
                    onFinish={(e) => { OnSubmit(); }}
                    id="FormCad"
                >
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Titulo" rules={[{ required: true, message: 'Digite o Titulo !' }]}>
                                <Input id="titulo" size="large" placeholder="titulo" type="text" value={titulo} onChange={(e) => setTitulo(e.target.value)} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Descrição" >
                                <Input required id="descricao" size="large" placeholder="Descrição" type="text" value={descricao} onChange={(e) => setDescricao(e.target.value)} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="Categoria" >
                                <Input required size="large" id="Categoria" placeholder="Categoria" type="text" value={categoria} onChange={(e) => setCategoria(e.target.value)} />
                            </Form.Item>
                        </Col>
                        {/* <Col span={24}>
                            <Form.Item label="Subtitulo" >
                                <Input size="large" required placeholder="Subtitulo" type="text" value={subtitulo} onChange={(e) => setSubtitulo(e.target.value)} />
                            </Form.Item>
                        </Col> */}
                        <Col span={24}>
                            <Form.Item label="Conteudo" >
                                <TextArea size="large" rows={5} required placeholder="Conteudo" value={conteudo} onChange={(e) => setConteudo(e.target.value)} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item label="Site" >
                        <SelectSites required value={selectValue} setValue={setSelectValue} />
                    </Form.Item>
                    <Collapse>
                        <Panel header="Campos Adicionais" key="2">
                            <Form.Item label="Campo adicional / Flag 1" >
                                <Input size="large" id="campoAdicional1" placeholder="Campo Adicional 1" type="text" value={campo1} onChange={(e) => setCampo1(e.target.value)} />
                            </Form.Item>
                            <Form.Item label="Campo adicional / Flag 2" >
                                <Input size="large" id="campoAdicional2" placeholder="Campo Adicional 2" type="text" value={campo2} onChange={(e) => setCampo2(e.target.value)} />
                            </Form.Item>
                            <Form.Item label="Campo adicional / Flag 3" >
                                <Input size="large" id="campoAdicional3" placeholder="Campo Adicional 3" type="text" value={campo3} onChange={(e) => setCampo3(e.target.value)} />
                            </Form.Item>
                        </Panel>
                    </Collapse>
                </Form>
            </Drawer>
        </MainCard>
    )
}