import React, { useState } from "react";
import MainCard from "../../Components/MainCard";
import { iSiteResponse } from "../../Types/Types";
import { toast } from "react-toastify";
import { Button, Drawer, Form, Input, Space, Switch } from "antd";
import SelectContratante from "../../Components/Select/selectContratante";
import TableSite from "../../Components/Table/SitesTable";
import api from "../../helpers/axios";
import SelectModulos from "../../Components/Select/selectModulos";

export default function Sites() {
    const [SiteResponse, setSiteResponse] = React.useState<iSiteResponse[]>([]);
    React.useEffect(() => {
        api.get('/sites').then((response) => {
            console.log(response.data)
            setSiteResponse(response.data);
        });
    }, []);

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const toggleDrawer = () => {
        setIsEdit(false);
        clearInputs();
        setIsDrawerOpen(!isDrawerOpen);
    };
    const [idContratante, setIdContratante] = useState<string>("");
    const [idModulo, setIdModulo] = useState<string[]>([]);
    const [nome, setNome] = useState<string>("");
    const [ativo, setAtivo] = useState<boolean>(false);

    function cadastro() {
        return api.post('/sites', {
            nome: nome,
            ativo: ativo,
            idModulos:idModulo,
            idEmpresa: idContratante,
        });
    }
    function atualizar() {
        return api.put(`/sites/${atualizaID}`, {
            nome: nome,
            ativo:ativo,
            idModulos:idModulo,
            idEmpresa: idContratante,
        });
    }
    async function OnSubmit() {
        if (!isEdit) {
            try {
                const resposta = await cadastro();
                toast.success("Cadastrado com Sucesso");
            } catch (error) {
                toast.error(`${error}`);
            }
        } else {
            try {
                const resposta = await atualizar();
                toast.success("Atualizado com Sucesso");
            } catch (error) {
                toast.error(`${error}`);
            }
        }
        toggleDrawer();
        api.get('/sites').then((response) => {
            setSiteResponse(response.data);
        });
    }
    function clearInputs() {
        setIdContratante("");
        setNome("");
    }
    const [atualizaID, setAtualizaID] = useState<string>("");
    const [isEdit, setIsEdit] = useState<boolean>(false)
    function edit(id: string) {
        setIsEdit(true);
        api.get(`/site/${id}`).then((response) => {
            const res = response.data as iSiteResponse;
            setAtualizaID(res._id.toString());
            setNome(res.nome);
            setAtivo(res.ativo);
            setIdContratante(res.idEmpresa._id.toString());
        });
        setIsDrawerOpen(true);
    }
    const onChange = (checked: boolean) => {
        setAtivo(checked);
    };
    return (
        <MainCard title="Sites">
            <div className="grid grid-cols-4 gap-4">
                <div className="col-span-4 text-right">
                    <Button type="primary" size="large" key="back" onClick={toggleDrawer}>
                        Novo Site
                    </Button>
                </div>
                <div className="col-span-4">
                    <TableSite Data={SiteResponse} setData={setSiteResponse} edit={edit} />
                </div>
            </div>
            <Drawer
                title="Cadastro de Site"
                placement="right"
                onClose={toggleDrawer}
                open={isDrawerOpen}
                extra={
                    <Space>
                        <Button onClick={toggleDrawer}>Cancel</Button>
                        <Button htmlType="submit" form="CadSite" type="primary">
                            Salvar
                        </Button>
                    </Space>
                }
            >
                <Form
                    layout="vertical"
                    onFinish={(e) => { OnSubmit(); }}
                    id="CadSite"
                >
                    <Form.Item label="Nome" >
                        <Input size="large" required placeholder="Nome" type="text" value={nome} onChange={(e) => setNome(e.target.value)} />
                    </Form.Item>
                    <Form.Item label="Modulos">
                        <SelectModulos required value={idModulo} setValue={setIdModulo} />
                    </Form.Item>
                    <Form.Item label="Contratante">
                        <SelectContratante required value={idContratante} setValue={setIdContratante} />
                    </Form.Item>
                    {isEdit ?
                        <Form.Item label="Inativo/Ativo" >
                            <Switch checked={ativo} onChange={onChange} />
                        </Form.Item>
                        :
                        ""
                    }
                </Form>
            </Drawer>
        </MainCard>
    )
}