import { useContext, useState } from 'react';
import Bg from './background';
import { ToastContainer, toast } from 'react-toastify';
import { Form, Input, Checkbox, Button } from 'antd';
import { BsPerson } from 'react-icons/bs';
import { AiFillLock } from 'react-icons/ai';
import { userContext } from '../../context/usuarioContext';
import api from '../../helpers/axios';
import { useHistory } from "react-router-dom";
import { iUserData } from '../../Types/Types';

function Login() {
  const history = useHistory();
  const { setUser } = useContext(userContext);
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  async function handleSubmit(event: { preventDefault: () => void; }) {
    const id = toast.loading("Please wait...");
    await api.post('/login', { email, senha })
      .then(response => {
        // toast.success("Login Feito com Sucesso !");
        toast.update(id, {render: "Bem Vindo !", type: "success", isLoading: false});
        localStorage.setItem("Token", response.data.token);
        localStorage.setItem("UserID", response.data.usuario._id);
        api.get(`/usuario/${response.data.usuario._id}`, {
          headers: {
            Authorization: `Bearer ${response.data.token}`
          }
        }).then((res) => {
          const user = res.data as iUserData;
          setUser(user);
        });
        history.push("/dashboard");
      })
      .catch(error => {
        toast.update(id, {render: error.response.data.message, type: "error", isLoading: false });
        // toast.error(`${error.response.data.message}`);
        console.error(error);
      });
  }
  return (
    <Bg>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <div className="p-10 xs:p-0 mx-auto md:w-full md:max-w-md">
        <div className="bg-white shadow w-full rounded-lg divide-y divide-gray-200">
          <div className="px-5 py-7">
            <Form
              name="normal_login"
              // initialValues={{ remember: true }}
              id='FormLogin'
              onFinish={handleSubmit}
            >
              <Form.Item
                name="username"
                rules={[{ required: true, message: 'Digite o Email!' }]}
              >
                <Input size='large' type="email" prefix={<BsPerson className="site-form-item-icon" />} placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[{ required: true, message: 'Digite a Senha!' }]}
              >
                <Input.Password
                  prefix={<AiFillLock className="site-form-item-icon" />}
                  type="password"
                  placeholder="Senha"
                  size='large'
                  value={senha} onChange={(e) => setSenha(e.target.value)}
                />
              </Form.Item>
              {/* <Form.Item>
                <Form.Item name="remember" valuePropName="checked" noStyle>
                  <Checkbox>Lembre de mim</Checkbox>
                </Form.Item>

                <a className="login-form-forgot" href="">
                  Forgot password
                </a>
              </Form.Item> */}

              <Form.Item className='pt-10'>
                <Button style={{ width: "100%" }} size='large' type="primary" htmlType="submit">
                  Log in
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </Bg>
  );
}

export default Login;
