import React, { useState } from "react";
import MainCard from "../../Components/MainCard";
import { iImagensListaCategoria } from "../../Types/Types";
import { toast } from "react-toastify";
import { UploadOutlined } from '@ant-design/icons';
import ImageList from "../../Components/ImageList";
import { Button, Drawer, Form, Input, Space, Upload, UploadFile } from "antd";
import { UploadChangeParam } from "antd/es/upload";
import api from "../../helpers/axios";
import SelectSites from "../../Components/Select/selectSites";

interface idSite {
    _id: string;
    nome: string;
    ativado: boolean;
    empresa: string;
}

export default function Imagens() {
    const [descricao, setDescricao] = React.useState<string>("");
    const [categoria, setCategoria] = React.useState<string>("");
    const [titulo, setTitulo] = React.useState<string>("");
    const [idSite, setIdSite] = React.useState<string>("");
    const [imagemBase64, setImagemBase64] = React.useState<string | null>(null);


    const [imagensData, setImagensData] = React.useState<iImagensListaCategoria[]>([]);
    React.useEffect(() => {
        api.get('/imagens').then((response) => {
            setImagensData(response.data);
        });
    }, []);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const toggleDrawer = () => {
        clearInputs();
        setIsDrawerOpen(!isDrawerOpen);
    };
    const [selectValue, setSelectValue] = useState<string>("");
    function cadastro() {
        return api.post('/imagen', {
            titulo: titulo,
            descricao: descricao,
            categoria: categoria,
            imagem: imagemBase64,
            idSite: idSite,
        });
    }
    async function OnSubmit() {
        try {
            const resposta = await cadastro();
            toast.success("Cadastrado com Sucesso");
            toggleDrawer();
        } catch (error) {
            console.log("aaa")
            toast.error(`${error}`);
        }
        api.get('/imagens').then((response) => {
            setImagensData(response.data);
        });
    }
    function clearInputs() {
        setSelectValue("");
    }
    function uploadFile(event: UploadChangeParam<UploadFile<any>>) {
        if (event.fileList.length > 0) {
            const file = event.fileList[0].originFileObj as File;
            const reader = new FileReader();
            reader.onload = () => {
                const base64String = reader.result as string;
                setImagemBase64(base64String);
            };
            reader.readAsDataURL(file);
        } else {
            setImagemBase64(null);
        }
    }
    return (
        <MainCard title="Imagens/Banners - Por categoria">
            <div className="grid grid-cols-4 gap-4">
                <div className="col-span-4 text-right">
                    <Button type="primary" size="large" onClick={toggleDrawer}>
                        Nova Imagem
                    </Button>
                </div>
                <div className="col-span-4">
                    <ImageList data={imagensData} />
                </div>
            </div>
            <Drawer
                title="Cadastro de Contratante"
                placement="right"
                onClose={toggleDrawer}
                open={isDrawerOpen}
                extra={
                    <Space>
                        <Button onClick={toggleDrawer}>Cancel</Button>
                        <Button htmlType="submit" form="FromCad" type="primary">
                            Salvar
                        </Button>
                    </Space>
                }
            >
                <Form
                    layout="vertical"
                    onFinish={(e) => { OnSubmit(); }}
                    id="FromCad"
                >
                    <Form.Item label="Titulo" >
                        <Input size="large" required placeholder="Titulo" type="text" value={titulo} onChange={(e) => setTitulo(e.target.value)} />
                    </Form.Item>
                    <Form.Item label="Descrição" >
                        <Input size="large" required placeholder="Descrição" type="text" value={descricao} onChange={(e) => setDescricao(e.target.value)} />
                    </Form.Item>
                    <Form.Item label="Categoria" >
                        <Input size="large" required placeholder="Categoria" type="text" value={categoria} onChange={(e) => setCategoria(e.target.value)} />
                    </Form.Item>
                    <Form.Item label="Imagem" >
                        <Upload
                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            listType="picture"
                            onChange={uploadFile}
                            beforeUpload={() => false}
                            maxCount={1}
                        >
                            <Button icon={<UploadOutlined />}>Upload</Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item label="Site">
                        <SelectSites value={idSite} setValue={setIdSite} />
                    </Form.Item>
                </Form>
            </Drawer>
        </MainCard>
    )
}