import React, { createContext, useState } from 'react';
import { iUserData } from '../Types/Types';

type userContextProps = {
    user:iUserData |undefined;
    setUser:Function;
}
export const userContext = createContext({} as userContextProps);


export const UserProvider = ({ children }: { children: React.ReactNode }) => {
    const [user, setUser] = useState<iUserData | undefined>({} as iUserData);
    return <userContext.Provider value={{ user, setUser }}><>{children}</></userContext.Provider>
}