import React from "react";
import { ToastContainer } from "react-toastify";

type MainCardProps = {
  title: string;
  children: React.ReactNode;
};

const MainCard: React.FC<MainCardProps> = ({ title, children }) => {
  return (
    <div className="container mx-auto py-4 overflow-y-auto font-sans">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      {/* Same as */}
      <h1 className="font-sans text-4xl text-gray-900 my-5">{title}</h1>
      {children}
    </div>

  );
};

export default MainCard;