import { useContext } from "react";
import MainCard from "../../Components/MainCard";
import { userContext } from "../../context/usuarioContext";

export default function Home() {
    const {user} = useContext(userContext);
    const token = localStorage.getItem("Token");
    return (
        <MainCard title="Home">
            {/* {user?.nome} */}
            <div className="grid grid-cols-4 gap-4">
                <div className="bg-red-500 h-24 md:col-span-2"></div>
                <div className="bg-green-500 h-24 md:col-span-2"></div>
                <div className="bg-blue-500 h-24 md:col-span-2"></div>
                <div className="bg-yellow-500 h-24 md:col-span-2"></div>
                <div className="bg-pink-500 h-24 md:col-span-2"></div>
                <div className="bg-purple-500 h-24 md:col-span-2"></div>
            </div>
        </MainCard>
    );
}
