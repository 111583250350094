import { Badge, Button, Card, Col, Popconfirm, Row } from 'antd';
import { Image } from 'antd';
import { Typography } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import { FaPen, FaTrash } from 'react-icons/fa';
const { Title } = Typography;

type props = {
    Titulo: string;
    descricao: string;
    categoria: string;
    imagem: string;
}

export default function ImageCard({ Titulo, descricao, categoria, imagem }: props) {
    return (
        <Card
            style={{ width: 250,maxHeight:400 }}
            cover={
                <img
                    alt={Titulo}
                    src={imagem}
                    style={{padding:"20px"}}
                />
            }
            actions={[
                <FaPen />,
                <FaTrash />,
            ]}
        >
            <h4>{Titulo}</h4>
            <p>{descricao}</p>
        </Card>
        // <Card style={{ padding: "15px",marginBottom:"5px" }}>
        //     <Row>
        //         <Col span={3} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        //             <Image
        //                 width={200}
        //                 src={imagem}
        //             />
        //         </Col>
        //         <Col span={20}>
        //             <div style={{ display: "flex", flexDirection: "column", height: "100%", marginLeft: "20px" }}>
        //                 <Title level={4}>{Titulo}</Title>
        //                 <Paragraph>{descricao}</Paragraph>
        //             </div>
        //         </Col>
        //         <Col span={1}>
        //             <div style={{ display: "flex", flexDirection: "column", height: "100%", marginLeft: "20px", justifyContent: "end", marginTop: "5px" }}>
        //                 <Button type="text" icon={<FaPen />} />
        //                 <Popconfirm
        //                     title="Deletar Contratante !"
        //                     description="Tem certeza que deseja deletar ?"
        //                     // onConfirm={() => startDelete(row._id)}
        //                     // onCancel={handleOpenCloseModalConfirm}
        //                     okText="Sim"
        //                     cancelText="Não"
        //                 >
        //                     <Button type="text" danger icon={<FaTrash />} />
        //                 </Popconfirm>
        //             </div>
        //         </Col>
        //     </Row>
        // </Card>
    )
}