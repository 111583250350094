import { ComponentType, useContext, useState } from "react";
import { RouteProps, RouteComponentProps, Route, Redirect } from "react-router-dom";
import SideBar from "../Components/SideBar";
import Header from "../Components/Header";
import React from "react";
import { userContext } from "../context/usuarioContext";
import api from "../helpers/axios";
import { iUserData } from "../Types/Types";
import { ADMID } from "../Components/utils/exportVariables";

interface PrivateRouteProps extends RouteProps {
    component: ComponentType<RouteComponentProps<any>> | ComponentType<any>;
    adm?: boolean
}

export default function PrivateRoute({ component: Component, adm, ...rest }: PrivateRouteProps) {
    const [asideOpen, setAsideOpen] = useState(true);
    const { user, setUser } = useContext(userContext);
    const [isadm, setAdm] = useState<boolean>(false);

    React.useEffect(() => {
        // setAdm(isAdm())
        if (!user?._id) {
            api.get(`/usuario/${localStorage.getItem("UserID")}`).then((res) => {
                const user = res.data as iUserData;
                setUser(user);
            });
        }
    }, []);
    function isAllow() {
        // if (user?._id) {
        //     if (adm) {
        //         if (user.idPermissao.find( item => item._id === ADMID)) {
        //             return true;
        //         }
        //     } else {
        //         return true;
        //     }
        // } else {
        //     return false;
        // }
        return true;
    }
    return (
        <Route {...rest} render={(props) =>
            // #EOE1DC #7c8da5
            isAllow() ? (
                <>
                    <div className=" h-screen">
                        <Header asideOpen={asideOpen} setAsideOpen={setAsideOpen}></Header>
                        <SideBar asideOpen={asideOpen} setAsideOpen={setAsideOpen} />
                        <Component {...props} />
                        {/* <div className="flex-1 bg-gray-200 overflow-y-auto h-full">
                    
                </div> */}
                    </div>

                </>
            ) : (
                <Redirect to="/login" />
            )
        } />
    );
}