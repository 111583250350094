import { Avatar, Dropdown, List, Space, message } from "antd";
import React, { useContext, useState } from "react";
import { BiLogOut, BiMenu } from "react-icons/bi";
import { BsX } from "react-icons/bs";
import type { MenuProps } from 'antd';
import { AiOutlineUser } from "react-icons/ai";
import { userContext } from "../../context/usuarioContext";
import { useHistory } from "react-router";

type HeaderProps = {
  asideOpen: boolean;
  setAsideOpen: Function;
};
const Header: React.FC<HeaderProps> = ({ asideOpen, setAsideOpen }) => {
  const history = useHistory();
  function exit() {
    localStorage.setItem("Token", "");
    localStorage.setItem("UserID", "");
    window.location.href = "/login";
  }
  function goTo(path: string) {
    history.push(path);
  }

  const items: MenuProps['items'] = [
    {
      label: (
        <span rel="noopener noreferrer">
          Configuração
        </span>
      ),
      key: '1',
      onClick:()=>goTo("/Configuracao"),
    },
    {
      label: (
        <span rel="noopener noreferrer">
          Sair
        </span>
      ),
      key: '2',
      icon: <BiLogOut style={{ fontSize: "22px" }}/>,
      danger: true,
      onClick:()=>exit(),
    },
  ];
  const menuProps = {
    items
  };
  const { user } = useContext(userContext);
  return (
    <header className="flex items-center justify-between bg-[#1d2538] px-4 py-3 shadow-md">
      <button
        id="btnSidebarToggler"
        type="button"
        className={`bg-transparent border-0 cursor-pointer p-1 text-2xl text-white text-right h-fit `} //${asideOpen ? "hidden" : ""}
        onClick={() => {
          setAsideOpen(!asideOpen);
        }}
      >
        {asideOpen ? <BsX className="" /> : <BiMenu className="" />}
      </button>
      <div className="mr-[4%]">
        <Dropdown.Button menu={menuProps} placement="bottom" icon={<AiOutlineUser />}>
          {user?.nome}
        </Dropdown.Button>
      </div>
    </header>
  );
};

export default Header;