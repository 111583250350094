import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import Login from './Views/login';
import App from './App';
import LandingPage from './Views/LandingPage';
import Home from './Views/Home/Home';
import Usuario from './Views/Usuario/Usuario';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
);