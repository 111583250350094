import React, { useState } from "react";
import MainCard from "../../Components/MainCard";
import axios from "axios";
import { iEmpresaResponse } from "../../Types/Types";
import { toast } from "react-toastify";
import ContratanteTable from "../../Components/Table/ContratanteTable";
import { Button, Drawer, Form, Input, Modal, Space } from "antd";
import Label from "../../Components/Label";
import api from "../../helpers/axios";

interface idSite {
    _id: string;
    nome: string;
    ativado: boolean;
    empresa: string;
}

export default function Contratantes() {
    const [empresaResponse, setEmpresaResponse] = React.useState<iEmpresaResponse[]>([]);
    const [nome, setNome] = useState<string>("");
    const [isDrawerOpen, setisDrawerOpen] = useState(false);
    React.useEffect(() => {
        api.get('/empresas').then((response) => {
            console.log(response.data)
            setEmpresaResponse(response.data);
        });
    }, []);
    const toggleDrawer = () => {
        clearInputs();
        setisDrawerOpen(!isDrawerOpen);
    };


    function cadastro() {
        return api.post('/empresa', {
            nome: nome,
        });
    }
    function atualizar() {
        return api.put(`/empresa/${atualizaID}`, {
            nome: nome,
        });
    }
    async function OnSubmit() {
        if (!isEdit) {
            try {
                const resposta = await cadastro();
                toast.success("Cadastrado com Sucesso");
            } catch (error) {
                toast.error(`${error}`);
            }
        } else {
            try {
                const resposta = await atualizar();
                api.get('/clientes').then((response) => {
                    console.log(response.data)
                });
                toast.success("Atualizado com Sucesso");
            } catch (error) {
                toast.error(`${error}`);
            }
        }
        toggleDrawer();
        api.get('/empresas').then((response) => {
            console.log(response.data)
            setEmpresaResponse(response.data);
        });
    }
    function clearInputs() {
        setNome("");
    }
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [atualizaID, setAtualizaID] = useState<string>("");
    function edit(id: string) {
        setIsEdit(true);
        api.get(`/empresa/${id}`).then((response) => {
            const res = response.data as iEmpresaResponse;
            setAtualizaID(res._id.toString());
            setNome(res.nome.toString());
        });
        setisDrawerOpen(true);
    }
    return (
        <MainCard title="Contratante">
            <div className="grid grid-cols-4 gap-4">
                <div className="col-span-4 text-right">
                    <Button type="primary" size="large" key="back" onClick={toggleDrawer}>
                        Novo Contratante
                    </Button>
                </div>
                <div className="col-span-4">
                    <ContratanteTable Data={empresaResponse} setData={setEmpresaResponse} edit={edit}/>
                </div>
            </div>
            <Drawer
                title="Cadastro de Contratante"
                placement="right"
                onClose={toggleDrawer}
                open={isDrawerOpen}
                extra={
                    <Space>
                        <Button onClick={toggleDrawer}>Cancel</Button>
                        <Button htmlType="submit" form="FromCad" type="primary">
                            Salvar
                        </Button>
                    </Space>
                }
            >
                <Form
                    layout="vertical"
                    onFinish={(e) => { OnSubmit(); }}
                    id="FromCad"
                >
                    <Form.Item label="Nome" >
                        <Input size="large" required placeholder="Nome" type="text" value={nome} onChange={(e) => setNome(e.target.value)} />
                    </Form.Item>
                </Form>
            </Drawer>
        </MainCard>
    )
}