import React, { useState } from "react";
import MainCard from "../../Components/MainCard";
import { iModuloData, iPermissoesData } from "../../Types/Types";
import { toast } from "react-toastify";
import { Button, ColorPicker, Drawer, Form, Input, Space } from "antd";
import api from "../../helpers/axios";
import TablePermissoes from "../../Components/Table/PermissoesTable";
import SelectSites from "../../Components/Select/selectSites";
import TableModulos from "../../Components/Table/ModuloTable";

export default function Modulos() {
    const [tableResponse, setTableResponse] = React.useState<iModuloData[]>([]);
    const [nome, setNome] = useState<string>("");
    const [descricao, setDescricao] = useState<string>("");
    const [cor, setCor] = useState<string>("#c2c2c2");
    const [isDrawerOpen, setisDrawerOpen] = useState(false);
    React.useEffect(() => {
        api.get('/modulos').then((response) => {
            console.log(response.data)
            setTableResponse(response.data);
        });
    }, []);
    const toggleDrawer = () => {
        clearInputs();
        setIsEdit(false);
        setisDrawerOpen(!isDrawerOpen);
    };


    function cadastro() {
        return api.post('/modulo', {
            nome: nome,
            descricao: descricao,
            cor:cor
        });
    }
    function atualizar() {
        return api.put(`/modulo/${atualizaID}`, {
            nome: nome,
            descricao: descricao,
            cor:cor
        });
    }
    async function OnSubmit() {
        if (!isEdit) {
            try {
                const resposta = await cadastro();
                toast.success("Cadastrado com Sucesso");
            } catch (error) {
                toast.error(`${error}`);
            }
        } else {
            try {
                const resposta = await atualizar();
                toast.success("Atualizado com Sucesso");
            } catch (error) {
                toast.error(`${error}`);
            }
        }
        toggleDrawer();
        api.get('/modulos').then((response) => {
            console.log(response.data)
            setTableResponse(response.data);
        });
    }
    function clearInputs() {
        setNome("");
        setDescricao("");
        setCor("#c2c2c2")
    }
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [atualizaID, setAtualizaID] = useState<string>("");
    function edit(id: string) {
        setIsEdit(true);
        api.get(`modulo/${id}`).then((response) => {
            const res = response.data as iModuloData;
            setAtualizaID(res._id.toString());
            setDescricao(res.descricao);
            setNome(res.nome.toString());
            setCor(res.cor);
        });
        setisDrawerOpen(true);
    }
    return (
        <MainCard title="Modulos">
            <div className="grid grid-cols-4 gap-4">
                <div className="col-span-4 text-right">
                    <Button type="primary" size="large" key="back" onClick={toggleDrawer}>
                        Novo Modulo
                    </Button>
                </div>
                <div className="col-span-4">
                    <TableModulos Data={tableResponse} setData={setTableResponse} edit={edit} />
                </div>
            </div>
            <Drawer
                title="Cadastro de Contratante"
                placement="right"
                onClose={toggleDrawer}
                open={isDrawerOpen}
                extra={
                    <Space>
                        <Button onClick={toggleDrawer}>Cancel</Button>
                        <Button htmlType="submit" form="FromCad" type="primary">
                            Salvar
                        </Button>
                    </Space>
                }
            >
                <Form
                    layout="vertical"
                    onFinish={(e) => { OnSubmit(); }}
                    id="FromCad"
                >
                    <Form.Item label="Nome" >
                        <Input size="large" required placeholder="Nome" type="text" value={nome} onChange={(e) => setNome(e.target.value)} />
                    </Form.Item>
                    <Form.Item label="Descrição" >
                        <Input size="large" required placeholder="Descrição" type="text" value={descricao} onChange={(e) => setDescricao(e.target.value)} />
                    </Form.Item>
                    <Form.Item label="Cor da Tag" >
                        <ColorPicker format="hex" value={cor} onChange={(e) => setCor(`#${e.toHex()}`)} />
                    </Form.Item>
                </Form>
            </Drawer>
        </MainCard>
    )
}