import { Button, Popconfirm, Space, Table, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { iModuloData, iPermissoesData } from '../../../Types/Types';
import { FaPen, FaTrash } from 'react-icons/fa';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import api from '../../../helpers/axios';

type TableProps = {
    Data: iModuloData[];
    setData: Function;
    edit: Function;
};

export default function TableModulos({ Data, setData, edit }: TableProps) {
    async function startDelete(id: string) {
        await api.delete(`/permissao/${id}`)
            .then((response) => {
                toast.success("Contratante excluido com Sucesso !")
                // console.log(response);
                setData(Data.filter(item => item._id !== id));
            })
            .catch((error: AxiosError) => {
                console.error(error);
                toast.error(error.message);
            });
    }
    const columns: ColumnsType<iModuloData> = [
        {
            title: 'Cor',
            key: 'cor',
            dataIndex: 'cor',
            width: '5%',
            render: (_, { cor,nome }) => (
                <>
                    <Tag color={cor}>
                        {nome}
                    </Tag>
                </>
            ),
        },
        // {
        //     title: 'Nome Contratante',
        //     dataIndex: 'nome',
        //     key: 'nome',
        //     width: '5%',
        // },
        {
            title: 'Descrição',
            dataIndex: 'descricao',
            key: 'descricao',
            width: '5%',
        },
        {
            title: '',
            key: '_id',
            width: '1%',
            render: (_, row) => (
                <Space size="middle">
                    <Button type="text" onClick={()=>{edit(row._id)}}><FaPen /></Button>
                    <Popconfirm
                        title="Deletar Contratante !"
                        description="Tem certeza que deseja deletar ?"
                        onConfirm={()=> startDelete(row._id)}
                        // onCancel={handleOpenCloseModalConfirm}
                        okText="Sim"
                        cancelText="Não"
                    >
                        <Button type="text" danger><FaTrash /></Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];
    return (
        <Table columns={columns} dataSource={Data}/>
    )
}
